
.icons-svg {
	&--refresh {
		stroke: none;
		fill: white;
		margin-top: -4px;
	}
	&--spam {
		stroke: none;
		fill: @green;
	}
}
.icons {
	&--wiki {
		margin-top: -3px;
	}
}