/* Page styles
   =============================== */

.page {
	padding: 25px 0px;

	&__title {
		margin: 0px 0px 20px;
		font-size: 26px;
		font-weight: 600;
	}

	&__subtitle {
		margin: 0px 0px 20px;
		font-size: 22px;
		font-weight: 600;
	}

}