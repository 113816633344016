/* Forms styles
   =============================== */


select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	background-color: @login-page !important;
	transition: background-color 0s ease-in-out 0s;
	-webkit-text-fill-color: #fff !important;
	-webkit-box-shadow: 0px 0px 0px 1000px @login-page inset !important;
	box-shadow: 0px 0px 0px 1000px @login-page inset !important;
}


input[type="checkbox"],
input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
	/*display:none;*/
	position: absolute;
	margin: 4px 0px 0px 3px;
	z-index: 1;
}

input[type="checkbox"] + span,
input[type="radio"] + span {
	display:inline-block;
	width:28px;
	height:28px;
	margin:-2px 10px 0 0;
	vertical-align:middle;
	background:url(check_radio_sheet.png) left top no-repeat;
	cursor:pointer;
	position: relative;
	&:after,
    &:before {
		content: "";
		display: block;
		position: absolute;
		z-index: 10;
    }
	&:before {
		background-color: white;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		border-width: 1px;
		border-style: solid;
		border-color: @blue-border;
	}
	&:after {
		background-color: transparent;
		width: 14px;
		height: 14px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
input[type="radio"] + span {
	&:before {
		border-radius: 50%;
	}
	&:after {
		border-radius: 50%;
	}
}
input[type="checkbox"] + span {
	&:before {
		border-radius: 2px;
	}
	&:after {
		border-radius: 2px;
	}
}
input[type="checkbox"] + span .fa {
	color: @blue-bg;
	font-size: 24px;
	position: absolute;
	top: 5px;
	left: 2px;
	opacity: 0.0;
	z-index: 10;
	transform: scale(0, 0);
	transition: all 0.35s;
}
input[type="checkbox"]:checked + span .fa {
	position: relative;
	top: 0px;
	left: 2px;
	transform: scale(1.0, 1.0);
	opacity: 1.0;
}
input[type="radio"]:checked + span {
	&:after {
		background-color: @blue-bg;
	}
}
span.error {
	font-size: 14px;
	line-height: 1.1;
	display: block;
	color: @red;
	margin: -15px 0px 15px;
}
.form-control {
	border-radius: 0px;
	margin-bottom: 20px;
	min-height: 48px;
	padding: 10px 15px;
	border-color: @blue-border;
	&.error {
		border-color: @red;
	}
}

.btn {
	background-color: @lt-blue-bg;
	border-radius: 0px;
	border-width: 1px;
	border-style: solid;
	border-color: @blue-border;

	font-weight: 700;

	height: 48px;
	line-height: 27px;
	padding: 10px 15px;
	margin: 0px 0px 20px;
	text-transform: uppercase;

	/*transition: all 0.15s ease;*/

	&:hover {
		background-color: @blue-bg;
		color: white;
		border-color: @blue-bg;
	}
	
	&--sm {
		font-size: 14px;
	}
	
	.fa {
		font-size: 28px;
		line-height: 0.9;
	}	
	
	&--width-100 {
		width: 100%;
	}

	&--empty {
		background-color: #e4edf1;
		border-color: @blue-border;
	}
	&--blue {
		background-color: @blue-bg;
		border-color: @blue-border;
		color: white;
	}
	
	&--arrow {
		padding: 10px 30px 10px 25px;
		border: 0px none;
		position: relative;
		&:after {
			content: "";
			display: block;
			border-width: 24px 0 24px 15px;
			border-color: #fff #fff #fff transparent;
			position: absolute;
			border-style: solid;
			top: 0;
			right: 0;
		}
	}

}
