.icons {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.1;
  position: relative;
  
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 100;
  }
}

.icons--add-hover {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -85px -70px;
    height: 16px;
    width: 16px;
  }
}
.icons--add {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -24px -101px;
    height: 16px;
    width: 16px;
  }
}
.icons--checkbox {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: 0px -101px;
    height: 28px;
    width: 14px;
  }
}
.icons--delete-hover {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -152px -40px;
    height: 14px;
    width: 13px;
  }
}
.icons--delete {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -129px -40px;
    height: 14px;
    width: 13px;
  }
}
.icons--edit-hover {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -111px -70px;
    height: 16px;
    width: 16px;
  }
}
.icons--edit {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -137px -70px;
    height: 16px;
    width: 16px;
  }
}
.icons--exit-hover {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -31px -70px;
    height: 16px;
    width: 17px;
  }
}
.icons--exit {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -58px -70px;
    height: 16px;
    width: 17px;
  }
}
.icons--help-hover {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -50px -101px;
    height: 15px;
    width: 15px;
  }
}
.icons--help {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -75px -101px;
    height: 15px;
    width: 15px;
  }
}
.icons--link {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: 0px -70px;
    height: 21px;
    width: 21px;
  }
}
.icons--refresh-hover {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -81px -40px;
    height: 14px;
    width: 14px;
  }
}
.icons--refresh {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -105px -40px;
    height: 14px;
    width: 14px;
  }
}
.icons--select-arrow {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -49px -40px;
    height: 14px;
    width: 22px;
  }
}
.icons--select2-remove-hover {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -124px -101px;
    height: 9px;
    width: 9px;
  }
}
.icons--select2-remove {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -143px -101px;
    height: 9px;
    width: 9px;
  }
}
.icons--support-spacebox {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: 0px 0px;
    height: 30px;
    width: 170px;
  }
}
.icons--task {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: -100px -101px;
    height: 13px;
    width: 14px;
  }
}
.icons--wiki {
  &:after {
    background-image: url(../img/sprites/dist/sprites.png);
    background-position: 0px -40px;
    height: 20px;
    width: 39px;
  }
}
