/* Catalog styles
   =============================== */

.catalog {
	padding: 0px 15px;
	margin-bottom: 30px;
	&__item {
		font-family: @font-georgia;
		font-size: 18px;
		&__num {
			display: block;
			text-align: right;
			padding-right: 15px;
			line-height: 1.5;
			color: #878787;
		}
		&__href {
			color: @blue-bg;
			text-decoration: underline;
			&:hover {
				color: #878787;
			}
		}
	}
	&__item + &__item {
		margin-top: 25px;
	}
	&__subtitle {
		font-family: @font-georgia;
		margin: 0px 0px 35px;
		& > &__series {
			font-family: arial, sans-serif;
			color: @gray;
			display: inline-block;
			line-height: 1.1;
			margin: 0px 0px 5px;
		}
	}
	&__result {
		font-size: 14px;
		margin: -10px 0px 0px;
	}
	&__element {
		margin: 40px 0px 0px;
		font-family: @font-georgia;
		&:last-child {
			margin-bottom: 60px;
		}
		p {
			margin: 0px 0px 10px;
		}
		.fa {
			font-size: 35px;
		}
		&__num {
			font-size: 14px;
			font-family: arial, sans-serif;
			display: block;
		}
		&__title {
			font-size: 22px;
			text-decoration: none;
			display: inline-block;
			margin: 5px 0px 5px;
			&:focus,
			&:hover {
				color: @blue-bg;
				border-bottom-color: transparent;
				text-decoration: none;
			}
		}
		&__author {
			font-size: 16px;
			display: block;
		}
		&__date {
			font-size: 16px;
			display: block;
		}
	}
	&__element__one {
		margin: 0px 0px 40px;
		font-family: @font-georgia;
		&:last-child {
			margin-bottom: 60px;
		}
		p {
			margin: 0px 0px 10px;
		}
		&__num {
			font-size: 16px;
			font-family: arial, sans-serif;
			display: block;
			margin: 0px 0px 5px;
		}
		&__title {
			font-size: 24px;
			line-height: 1.25;
			color: @base-color;
			text-decoration: none;
			display: inline-block;
			margin: 0px 0px 25px;
			&:hover {
				color: @base-color;
				text-decoration: none;
			}
		}
		&__author {
			font-size: 18px;
			display: block;
		}
		&__date {
			font-size: 16px;
			display: block;
		}
		&__comment {
			margin: -15px 0px 20px;
			display: block;
			font-size: 14px;
			
			color: lighten(@base-color, 25%);
		}
		&__content {
			margin: 40px 0px 60px;
			color: @base-color;
			font-family: @font-georgia;
			font-size: 16px;
		}
	}
}