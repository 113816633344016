

.pagination {
	margin: 50px 0px 0px;
	border-radius: 0px;
	& > li + li {
		& > a {
			margin-left: 10px;
		}
	}
	& > li {
		&:first-child,
		&:last-child {
			& > a {
				border-radius: 0px;
			}
		}
		& > a,
		& > span {
			border-radius: 0px;
			border-color: lighten(@base-color, 50%);
			color: @base-color;
			&:hover {
				background: @blue;
				border-color: @blue;
				color: white;
			}
		}
	}
}