/* Basket styles
   =============================== */

.basket {
	padding: 0px 20px;
	a {
		color: @blue-bg;
		.decorate {
			border-bottom: 1px solid;
		}
		&:hover {
			.decorate {
				border-bottom-color: transparent;
			}
		}
	}

	&__title {
		margin: 0px 0px 20px;
		font-size: 28px;
		font-weight: 600;
		font-family: @font-georgia;
		.fa {
			margin-right: 20px;
		}
	}
	&__stages {
		margin-bottom: 20px;
	}
	&__stage {
		&-item {
			background: @gray-bg;
			font-weight: 800;
			text-transform: uppercase;
			display: inline-block;
			font-size: 16px;
			font-weight: 700;
			height: 48px;
			line-height: 27px;
			padding: 10px 30px 10px 25px;
			position: relative;
			&:after {
				content: "";
				display: block;
				border-width: 24px 0 24px 15px;
				border-style: solid;
				border-color: white white white transparent;
				position: absolute;
				top: 0px;
				right: 0px;
			}
			&:last-child {
				padding-right: 15px;
				&:after {
					display: none;
				}
			}			
			&.active {
				background: @lt-blue;
				&:after {
					border-color: white white white @lt-blue;
				}
			}

		}
	}
	&__item + &__item {
		margin: 40px 0px 0px;
	}

	&__item {
		&:last-child {
			margin-bottom: 60px;
		}
		p {
			margin: 0px 0px 10px;
		}
		.fa {
			font-size: 20px;
			&.fa-mobile {
				font-size: 26px;
				position: relative;
				top: 2px;
			}
		}
		&__num {
			font-size: 14px;
			display: block;
		}
		&__title {
			font-size: 20px;
			text-decoration: none;
			display: inline-block;
			font-family: @font-georgia;
			margin: 10px 0px 10px;
			&:hover {
				color: @gray;
				border-bottom-color: transparent;
				text-decoration: none;
			}
		}
		&__author {
			font-size: 16px;
			display: block;
		}
		&__date {
			font-size: 16px;
			display: block;
		}
		&__price {
			display: block;
			font-size: 18px;
			text-transform: uppercase;
			text-align: right;
			margin-top: 1.75em;
		}
		& &__direct {
			color: @base-color;
			display: inline-block;
			margin: 20px 0px 10px;
			font-size: 18px;
			.fa {
				font-size: 22px;
				margin: -3px 5px 0px 0px;
				vertical-align: middle;
				color: @blue-bg;
			}
			.decorate {
				border-bottom: 1px solid;
			}
			&:focus,
			&:hover {
				text-decoration: none;
				color: @blue-bg;
				.decorate {
					border-color: transparent;
				}
			}
		}
	}
	&__result {
		margin: 40px 0px 40px;
		font-size: 18px;
		.btn {
			margin-top: 25px;
		}
		.rub {
			display: inline-block;
			min-width: 1em;
		}
	}
}