@iterations: 65;

.loopingClas (@i) when (@i >= 0) {
	.p-l-@{i} {
		padding-left:@i*1px!important;
	}
	.p-t-@{i} {
		padding-top:@i*1px!important;
	}
	.p-r-@{i} {
		padding-right:@i*1px!important;
	}
	.p-b-@{i} {
		padding-bottom:@i*1px!important;
	}
	.m-l-@{i} {
		margin-left:@i*1px!important;
	}
	.m-t-@{i} {
		margin-top:@i*1px!important;
	}
	.m-t--@{i} {
		margin-top:-1*@i*1px!important;
	}
	.m-r-@{i} {
		margin-right:@i*1px!important;
	}
	.m-b-@{i} {
		margin-bottom:@i*1px!important;
	}
	.loopingClas(@i - 5);

}
.loopingClas(@iterations);

/* Bootstrap customizable
   ========================================================================== */
.container-fluid {
	max-width: 960px;
}

html,
body {
	height: 100%;
	color: @base-color;
	font-family: arial, sans-serif;
}
hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid lighten(#878787, 20%);
}
.blue {
	color: @blue-bg;
}
.red {
	color: @red;
}
.green {
	color: @green;
}
.readmore__pane {
	background: #ecf6fa;
	margin: 40px 0px;
	padding: 20px 10px;
	.btn {
		margin: 0px;
	}
}
a {
	color: #339dde;
	&:hover,
	&:focus {
		color: #339dde;
		text-decoration: none;
		.decorate {
			border-bottom-color: transparent;
		}
	}
	.decorate {
		border-bottom: 1px solid;
	}
}
* {
	/*transition: color 0.08s, opacity 0.08s;*/
}
.d-b {
	display: block!important;
}
.d-i-b {
	display: inline-block!important;
}
.valign-middle {
	vertical-align: middle!important;
}
.valign-top {
	vertical-align: top!important;
}
.valign-bottom {
	vertical-align: bottom!important;
}
input,
select,
textarea,
a,button,
a:hover,button:hover,
a:focus,button:focus,
a:active,button:active,
a:active:focus,button:active:focus {
	&:focus,
	&:focus:active,
	&:active:focus {
		outline: none;
	}
}
ol {
	padding-left: 18px;
}
ul {
	padding-left: 18px;
	font-size: 15px;
	li {
		list-style-type: disc;
		position: relative;
		margin-top: 6px;
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0.5rem;
			margin-left: -14px;
		}
	}
	&.list {
		li {
			list-style-type: none;
		}
		&--default {
			padding: 0px 0px;
			margin: 0px 0px;
		}
		&--mdash {
			padding: 0px 0px 0px 24px;
			margin: 0px 0px;
			li {
				position: relative;
				&:after {
					content: "\2014";
					display: block;
					top: 0em;
					margin-left: -24px;
				}
			}
		}
	}
}

