/* Header styles
   =============================== */

.header {
	background-color: @blue-bg;
	border-width: 0px 0px 1px;
	border-style: solid;
	border-color: white;
	
	.container-fluid {
		& > .row {
			& > div:nth-last-child(2) .header__btn {
				margin-right: 12px;
			}
		}
	}
	
	.badge {
		background: @red;
		color: white;
		padding: 3px 6px;
		position: absolute;
	}

	a {
		color: white;
		&:hover,
		&:focus {
			text-decoration: none;
			&,
			.fa {
				color: @lt-blue;
			}
		}
	}

	sup {
		top: -0.8em;
		font-size: 14px;
		margin-left: 3px;
		&.badge {
			margin-left: -8px;
			top: 0.5em;
		}
	}

	.fa {
		font-size: 25px;
		color: white;
	}

	&__price {
		display: inline-block;
		font-size: 22px; 
		line-height: 1.25;
		font-weight: 700;
	}

	&__btn {
		display: block;
		position: relative;
		height: 50px;
		padding: 14px 0px 14px 0px;
		text-align: center;
		.red {
			color: @red;
		}
		&--price {
			padding-left: 10px;
		}
		&--rborder {
			border-width: 0px 1px 0px 0px;
			border-style: solid;
			border-color: white;
		}
		&--lborder {
			border-width: 0px 0px 0px 1px;
			border-style: solid;
			border-color: white;
		}
	}

}