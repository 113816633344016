/* Footer styles
   =============================== */


.footer {
	background: #fbfbfb;
	font-size: 14px;
	&__in {
		padding: 30px 15px 40px;
	}
	p {
		margin: 0px 0px 15px;
		&:last-child {
			margin: 0px;
		}
	}

	.fa {
		color: @red;
		font-size: 33px;
	}

}