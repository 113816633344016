/* Table styles
   =============================== */

.table {
    width: 100%;
	table-layout: fixed;
	border: 0px none;
	a {
		color: @green;
	}
	input {
		margin: 0px 0px 0px 5px;
		float: left;
	}
	.btn {
		vertical-align: top;
	}
	tbody {
		tr {
			&.disabled {
				opacity: 0.5;
				&:hover {
					background: transparent;
				}
			}
			&:nth-of-type(odd) {
				background: transparent;
			}
			&:hover {
				background: @ltbadge-bg;
			}
			td {
				background-color: transparent;
				max-width: 0px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 13px;
				line-height: 1.1;
				font-weight: 400;
				border-width: 0px 0px 1px;
				padding: 12px 0px 12px 12px;
				&:first-child {
					padding-left: 0px;
					text-align: center;
				}
			}
		}
	}
	thead {
		tr {
			th {
				background-color: @gray-bg;
				border-width: 0px;
				font-size: 12px;
				line-height: 1.1;
				color: @lt-gray;
				vertical-align: middle;
				padding: 9px 0px 8px 12px;
				&:first-child {
					padding-left: 0px;
					text-align: center;
				}
			}
		}
	}
	&__edit {
		display: inline-block;
		&:hover {
			.icons {
				.icons--edit-hover;
			}
		}
	}
	&__delete {
		display: inline-block;
		margin-left: 15px;
		&:hover {
			.icons {
				.icons--delete-hover;
			}
		}
	}
}
