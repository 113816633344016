
.icons {
	&--sm {
		line-height: 30px;
		width: 30px;
		height: 30px;
		font-size: 10px;
	}
	&--md {
		width: 50px;
		height: 50px;
		font-size: 15px;
		line-height: 50px;
	}
	&--lg {
		line-height: 80px;
		width: 80px;
		height: 80px;
		font-size: 20px;
	}
	&--photo {
		text-align: center;
		font-style: normal;
		vertical-align: middle;
		border-radius: 2px;
		color: white;
		font-weight: bold;
		&-red {
			background-color: @red;
		}
		&-gray {
			background-color: @lt-gray;
		}
		&-ltgray {
			background-color: @toolt-gray;
		}
		&-green {
			background-color: @green;
		}
	}
}