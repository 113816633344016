/* Login styles
   =============================== */

.login {

	padding: 0px 20px;
	margin-bottom: 40px;

	a {
		color: @base-color;
		.decorate {
			border-bottom: 1px solid;
		}
		&:hover {
			color: @blue;
			text-decoration: none;
			.decorate {
				border-bottom-color: transparent;
			}
		}
	}

	.btn {
		text-transform: none;
		margin-bottom: 10px;
	}

	&__form {
		background: @lt-blue-bg;
		padding: 20px 20px;
		margin-left: -20px;
		margin-right: -20px;
	}

	&__title {
		margin: 0px 0px 30px;
		font-size: 28px;
		font-family: @font-georgia;
		font-weight: 600;
		.fa {
			margin-right: 20px;
		}
	}

	&__subtitle {
		margin: 0px 0px 10px;
		font-size: 18px;
		line-height: 1.1;
		font-weight: normal;
	}

	label {
		font-size: 18px;
		font-weight: normal;
	}

	&__remember {
		float: right;
		& > a + a {
			margin-top: 5px;
		}
		& > a {
			font-size: 14px;
			float: right;
			clear: right;
			&:hover {
				text-decoration: none;
			}
		}
	}

	&__comment {
		font-size: 14px;
		color: lighten(@base-color, 25%);
	}

	.captcha {
		line-height: 43px;
		label {
			line-height: 1.1;
		}
		img {
			margin: 0px 10px 0px;
		}
		.form-control {
			margin: 0px;
		}
		.fa {
			font-size: 32px;
			vertical-align: middle;
			color: @blue-bg;
		}
	}

}