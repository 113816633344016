
/* Web font
   ========================================================================== */

@font-face {
    font-family: 'ralewayblack';
    src: url('../fonts/raleway-black/raleway-black-webfont.eot');
    src: url('../fonts/raleway-black/raleway-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-black/raleway-black-webfont.woff2') format('woff2'),
         url('../fonts/raleway-black/raleway-black-webfont.woff') format('woff'),
         url('../fonts/raleway-black/raleway-black-webfont.ttf') format('truetype'),
         url('../fonts/raleway-black/raleway-black-webfont.svg#ralewayblack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaybold';
    src: url('../fonts/raleway-bold/raleway-bold-webfont.eot');
    src: url('../fonts/raleway-bold/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-bold/raleway-bold-webfont.woff2') format('woff2'),
         url('../fonts/raleway-bold/raleway-bold-webfont.woff') format('woff'),
         url('../fonts/raleway-bold/raleway-bold-webfont.ttf') format('truetype'),
         url('../fonts/raleway-bold/raleway-bold-webfont.svg#ralewaybold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaylight';
    src: url('../fonts/raleway-light/raleway-light-webfont.eot');
    src: url('../fonts/raleway-light/raleway-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-light/raleway-light-webfont.woff2') format('woff2'),
         url('../fonts/raleway-light/raleway-light-webfont.woff') format('woff'),
         url('../fonts/raleway-light/raleway-light-webfont.ttf') format('truetype'),
         url('../fonts/raleway-light/raleway-light-webfont.svg#ralewaylight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaymedium';
    src: url('../fonts/raleway-medium/raleway-medium-webfont.eot');
    src: url('../fonts/raleway-medium/raleway-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-medium/raleway-medium-webfont.woff2') format('woff2'),
         url('../fonts/raleway-medium/raleway-medium-webfont.woff') format('woff'),
         url('../fonts/raleway-medium/raleway-medium-webfont.ttf') format('truetype'),
         url('../fonts/raleway-medium/raleway-medium-webfont.svg#ralewaymedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewayregular';
    src: url('../fonts/raleway-regular/raleway-regular-webfont.eot');
    src: url('../fonts/raleway-regular/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-regular/raleway-regular-webfont.woff2') format('woff2'),
         url('../fonts/raleway-regular/raleway-regular-webfont.woff') format('woff'),
         url('../fonts/raleway-regular/raleway-regular-webfont.ttf') format('truetype'),
         url('../fonts/raleway-regular/raleway-regular-webfont.svg#ralewayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaysemibold';
    src: url('../fonts/raleway-semibold/raleway-semibold-webfont.eot');
    src: url('../fonts/raleway-semibold/raleway-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-semibold/raleway-semibold-webfont.woff2') format('woff2'),
         url('../fonts/raleway-semibold/raleway-semibold-webfont.woff') format('woff'),
         url('../fonts/raleway-semibold/raleway-semibold-webfont.ttf') format('truetype'),
         url('../fonts/raleway-semibold/raleway-semibold-webfont.svg#ralewaysemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaythin';
    src: url('../fonts/raleway-thin/raleway-thin-webfont.eot');
    src: url('../fonts/raleway-thin/raleway-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-thin/raleway-thin-webfont.woff2') format('woff2'),
         url('../fonts/raleway-thin/raleway-thin-webfont.woff') format('woff'),
         url('../fonts/raleway-thin/raleway-thin-webfont.ttf') format('truetype'),
         url('../fonts/raleway-thin/raleway-thin-webfont.svg#ralewaythin') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'als_rublregular';
    src: url('../fonts/rouble/rouble-webfont.eot');
    src: url('../fonts/rouble/rouble-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/rouble/rouble-webfont.woff2') format('woff2'),
         url('../fonts/rouble/rouble-webfont.woff') format('woff'),
         url('../fonts/rouble/rouble-webfont.ttf') format('truetype'),
         url('../fonts/rouble/rouble-webfont.svg#als_rublregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Variables
   ========================================================================== */

b,strong {
	font-weight: 700;
}
.rub {
	font-family: 'als_rublregular';
	text-transform: none;
}
.font {
    &-raleway {
        &-black {
            font-family: 'ralewayblack';
        }
        &-light {
            font-family: 'ralewaylight';
        }
        &-medium {
            font-family: 'ralewaymedium';
        }
        &-regular {
            font-family: 'ralewayregular';
        }
        &-bold {
            font-family: 'ralewaybold';
        }
        &-semibold {
            font-family: 'ralewaysemibold';
        }
        &-thin {
            font-family: 'ralewaythin';
        }
    }
}

