

.mm-listview {
	& > li:not(.mm-divider):after {
		height: 1px;
		top: auto;
		bottom: 0px;
	}
	& > li {
		& > a {
			&:hover {
				background: rgba(255,255,255,0.5);
			}
		}
	}
}

.mm-menu {
	background: @lt-blue-bg;
	.mm-panel {
		background: transparent;
	}
	&.mm-current {
		.mm-panels {
			&:before {
				box-shadow: inset -1px 0px 10px 0px rgba(0,0,0,0.10);
				content: "";
				display: block;
				position: absolute;
				top: -15px;
				bottom: -15px;
				right: 0px;
				left: -15px;
			}
		}
	}
	&.mm-right {
		.mm-panels {
			&:before {
				box-shadow: inset 1px 0px 10px 0px rgba(0,0,0,0.10);
				content: "";
				display: block;
				position: absolute;
				top: -15px;
				bottom: -15px;
				right: -15px;
				left: 0px;
			}
		}
	}
}
