/* Search styles
   =============================== */

.search {
	background: @lt-blue-bg;
	padding: 15px 15px;
	border-bottom: 1px solid white;
	.media {
		.media-left {
			padding-top: 5px;
			padding-right: 15px;
		}
		.media-body {
			
		}
	}

	&__form {
		.input-group {
			.form-control {
				border-color: @blue-border;
				border-radius: 0px;
				height: 48px;
				padding: 10px 15px;
				color: @base-color;

				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					color: @base-color;
				}
				&::-moz-placeholder { /* Firefox 19+ */
					color: @base-color;
				}
				&:-ms-input-placeholder { /* IE 10+ */
					color: @base-color;
				}
				&:-moz-placeholder { /* Firefox 18- */
					color: @base-color;
				}

			}
			&-btn {
				.btn {
					border-color: @blue-border;
					border-radius: 0px;
					height: 48px;
					padding: 13px 25px;
					margin-bottom: 0px;
					.fa {
						font-size: 20px;
					}
					&:hover,
					&:active,
					&:focus {
						background-color: @blue-bg;
						border-color: @blue-bg;
						color: white;
						.fa {
							color: white;
						}
					}
				}
			}
		}
	}

}