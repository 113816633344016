.icons-svg {
	display: inline-block;
	vertical-align: middle;
	width: 1em;
	height: 1em;
	fill: none;
	stroke: none;
	stroke-width: 2;
	stroke-miterlimit: 10;
}

.icons-svg--attention-dark {
	font-size:(17.998/10)*1rem;
	width:(17.998/17.998)*1em;
}
.icons-svg--attention-light {
	font-size:(17.998/10)*1rem;
	width:(17.998/17.998)*1em;
}
.icons-svg--basket-gray {
	font-size:(20/10)*1rem;
	width:(18/20)*1em;
}
.icons-svg--basket-green {
	font-size:(20/10)*1rem;
	width:(18/20)*1em;
}
.icons-svg--basket-red {
	font-size:(20/10)*1rem;
	width:(18/20)*1em;
}
.icons-svg--cansel-gray {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icons-svg--cansel-green {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icons-svg--cansel-red {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icons-svg--link-gray {
	font-size:(21/10)*1rem;
	width:(21/21)*1em;
}
.icons-svg--link-green {
	font-size:(21/10)*1rem;
	width:(21/21)*1em;
}
.icons-svg--link-red {
	font-size:(21/10)*1rem;
	width:(21/21)*1em;
}
.icons-svg--refresh {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icons-svg--spam-green {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icons-svg--spam-red {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icons-svg--upload-gray {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icons-svg--upload-green {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icons-svg--upload-red {
	font-size:(20/10)*1rem;
	width:(20/20)*1em;
}
.icons-svg--user {
	font-size:(24.318/10)*1rem;
	width:(21/24.318)*1em;
}
